<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="getList()"
        ></el-button>
        <el-date-picker
          v-model="listQuery.paymentDate"
          type="year"
          placeholder="选择年份"
          size="small"
          style="width: 100px;"
          @change="getList"
          value-format="YYYY"
        />
        <el-input
          size="small"
          placeholder="请输入客户编码/客户名称"
          v-model.trim="listQuery.customerName"
          style="width: 200px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getList"
          style="margin-right: 10px"
          icon="Search"
          >搜索</el-button
        >

        <el-checkbox v-model="listQuery.paymentType" label="包含未审核通过的单据" size="small" @change="getList" />
      </div>
      <!-- <div>
        <el-button size="small" type="success" icon="Folder">导出</el-button>
      </div> -->
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table
        v-loading="loading"
        :data="list"
        :scroll-y="{ enabled: true, gt: 0, mode: 'wheel' }"
        height="auto"
        style="width: 100%"
        border
        stripe
        auto-resize
        size="mini"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{ resizable: true }"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column
          align="center"
          field="sortColumn"
          type="checkbox"
          width="50"
        >
        </vxe-column>
        <vxe-column field="customerNum" title="客户编码" width="180"></vxe-column>
        <vxe-column field="customerName" title="客户名称" width="180"> </vxe-column>
        <vxe-column field="oneMonthPayment" title="1月"> </vxe-column>
        <vxe-column field="twoMonthPayment" title="2月"> </vxe-column>
        <vxe-column field="threeMonthPayment" title="3月"> </vxe-column>
        <vxe-column field="fourMonthPayment" title="4月"> </vxe-column>
        <vxe-column field="fiveMonthPayment" title="5月"> </vxe-column>
        <vxe-column field="sixMonthPayment" title="6月"> </vxe-column>
        <vxe-column field="sevenMonthPayment" title="7月"> </vxe-column>
        <vxe-column field="eightMonthPayment" title="8月"> </vxe-column>
        <vxe-column field="nineMonthPayment" title="9月"> </vxe-column>
        <vxe-column field="tenMonthPayment" title="10月"> </vxe-column>
        <vxe-column field="elevenMonthPayment" title="11月"> </vxe-column>
        <vxe-column field="twelveMonthPayment" title="12月"> </vxe-column>
      </vxe-table>
    </div>

    <div class="page_container">
      <qzf-pagination
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
  <contractForm ref="ContractForm" />
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { proxyFeesList } from "@/api/newCrm";

const { proxy } = getCurrentInstance();
const list = ref([]);
const contentStyleObj = ref({});
const loading = ref(false);
const total = ref(0)
const listQuery = ref({
  page: 1,
  limit: 20,
  customerName: '',
  paymentDate: '',
  paymentType: false,
})


onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  listQuery.value.paymentDate = new Date().getFullYear().toString();
  getList()
});

function getList() {
  loading.value = true;
  proxyFeesList({
    ...listQuery.value,
    paymentType: listQuery.value.paymentType ? 0 : 1
  }).then((res) => {
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
      total.value = res.data.data.total
    }
  })
  .finally(() => loading.value = false);
}
</script>
<script>
export default {
  name: "chargeRefer",
};
</script>
<style scoped lang="scss">
.page_container{
  float: right;
  margin-top: 10px;
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:deep(.el-checkbox) {
  margin-right: 15px;
}
.style_form {
  .el-input {
    width: 147px;
  }
  .el-select {
    width: 147px;
    margin: 0;
  }
}
.filter-item {
  margin-right: 15px !important;
}
:deep(.el-dropdown-menu__item) {
  display: block;
}
</style>
